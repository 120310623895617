.notification {
    position: fixed;
    top: 160px;
    display: flex;
    justify-content: center;
    z-index: 5;

    .notification_wrapper {
        padding: 12px 24px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 12px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(168.33deg, #4898FB -10.69%, #4898FB -10.67%, #5C2EFC 162.15%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_warning {
        padding: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 15px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(168.33deg, #FA7D61 -10.69%, #FA7D61 -10.67%, #D34A2C 162.15%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_error {
        padding: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 15px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(#FA7D61 -10.69%, #D34A2C 74.83%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }
}

.notification_mobile {
    position: absolute;
    top: 15px;
    display: flex;
    width: 90%;
    justify-content: center;

    .notification_wrapper {
        padding: 16px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 12px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(168.33deg, #4898FB -10.69%, #4898FB -10.67%, #5C2EFC 162.15%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_warning {
        padding: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 15px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(168.33deg, #FA7D61 -10.69%, #FA7D61 -10.67%, #D34A2C 162.15%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_error {
        padding: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 15px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(#FA7D61 -10.69%, #D34A2C 74.83%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }
}