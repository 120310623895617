@import "../../../styles/breakpoints.scss";

.footer {
    width: 100%;
    background-color: var(--color-primary-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;    

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 65%;
        gap: 62px;
        padding: 48px 0;

        @include lg {
            width: 95%;
        }

        hr {
            width: 100%;
            border-top: 0;
            border-bottom: 1px solid var(--color-footer-swiper);
        }

        .footer_row {
            display: flex;

            @include lg {
                flex-direction: column;
                gap: 64px;

                &.mobile_inverted {
                    flex-direction: column-reverse;
                }

            }
        }

        .stratis_info {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            @include lg {
                width: 100%;
            }

            p {
                color: var(--color-white);
                text-align: left;
                margin: 0;
            }

            button {
                width: 80%;
                padding: 10px 30px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                height: 48px;
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;

                @include lg {
                    width: 60%;
                }
            }

        }
    
        .links {
            display: flex;
            flex: 1;
            gap: 36px;
            justify-content: space-around;
            align-items: flex-start;


            @include lg {
                flex-wrap: wrap;
            }
    
            .link_column {
                display: flex;
                flex-direction: column;
                gap: 24px;
                align-items: flex-start;

                @include lg {
                    flex: 1 0 30%;

                    &.mobile_fill_width {
                        flex: 1;
                        width: 80%;
                    }
                }

                .icons_row{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 32px;
                    

                    &.fixed_height {
                        height: 50px;

                        @include lg {
                            justify-content: flex-start;
                        }
                    }

                    .icon_container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #FAFAFA;
                        padding: 12px;
                        border-radius: 50%;
                        
                        a {
                            height: 24px;
                        }

                        svg {
                            fill: var(--color-footer-icon);
                        }

                        &:hover {
                            svg {
                                fill: var(--color-secondary-light);
                            }
                        }
                    }

                }

                .title {
                    font-size: 24px;
                    font-weight: 600;
                    color: var(--color-secondary-light);
                    margin: 0;

                    span {
                        color: var(--color-white);
                    }
                }

                a {
                    color: var(--color-white);
                    font-weight: 400;
                }
            }
        }
    }
}