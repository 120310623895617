@import "../../../styles/breakpoints.scss";

.header {
    display: flex;
    justify-content: center;
    background-color: var(--color-primary-dark);

    .wrapper {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;
        gap: 12px;

        a {
            color: var(--color-white);
            font-weight: 400;
            position: relative;

            &.login_link {
                height: fit-content;
            }

            .decorator_wrapper {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: -1px;
                height: 2px;
                width: 100%;

                .decorator {
                    height: 100%;
                    width: 0%;
                    background-color: var(--color-secondary-light);
                    transition: width 0.2s ease-out;
                }
            }

            &:hover {
                .decorator_wrapper {
                    .decorator {
                        width: 100%;
                    }
                }
            }
        }

        .menu {
            display: flex;
            align-items: center;
            gap: 32px;
        }

        .cat {
            display: flex;
            gap: 16px;
            align-items: center;
            
            button {
                padding: 10px 30px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                height: 48px;
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;
            }
        }

        .logout_wrapper {
            position: relative;

            button {
                background-color: var(--color-primary-dark);
                border: 0;
                color: var(--color-white);
                font-size: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;
                gap: 8px;

                svg {
                    path {
                        fill: var(--color-white);
                    }
                }

                p {
                    span {
                        color: var(--color-secondary-light);
                    }
                }

            }

            .menu_wrapper {
                position: absolute;
                display: none;
                background-color: var(--color-white);
                color: var(--color-primary-dark);
                width: 120%;
                border: 1px solid var(--color-border-gray);
                border-radius: 5px;
                padding: 12px 0;
                z-index: 10;
                

                &.active {
                    display: block;
                }

                button {
                    display: flex;
                    gap: 12px;
                    width: 100%;
                    font-size: 16px;
                    background-color: var(--color-white);
                    color: var(--color-primary-dark);
                    padding: 8px 12px;

                    &:hover {
                        background-color: var(--color-hovered-item);
                    }

                }
            }
        }

        
    }


}

.error_modal {
    background-color: var(--color-white);
    padding: 32px;
    border-radius: 8px;
    width: 50%;

    @include lg {
        width: 75%;
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        color: var(--color-purple-dark);
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 32px;

        button {
            flex: 1;
            color: var(--color-white);
            font-weight: 600;
            background-color: var(--color-secondary-light);
            border: 0;
            padding: 12px;
            border-radius: 8px;
            width: 200px;
            

            &.cancel {
                flex: none;
                width: 35%;
                border: 2px solid var(--color-purple-dark);
                color: var(--color-purple-dark);
                background-color: var(--color-white);
            }
        }
    }
}

.header_mobile {
    overflow: hidden;
    background-color: var(--color-primary);
    position: relative;
    padding: 0 24px;
    padding-top: 17px;
    padding-bottom: 7px;

    .wrapper {
        display: flex;
        justify-content: space-between;

        a {
            color: white;
            padding: 14px 16px;
            text-decoration: none;
            font-size: 17px;
            display: block;
    
            .icon {
                background: black;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
            }
    
        }

        .cat {
            button {
                padding: 5px 30px;
                margin-top: 6px;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 800;
                width: 150px;
                height: 48px;
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;
            }
        }

    }

    .menu_wrapper {
        max-height: 100vh;
        flex-direction: column;
        gap: 20px;
        padding-top: 60px;
        padding-bottom: 955PX;

        p {
            color: white;
            padding: 1px 16px;
            text-decoration: none;
            text-align: center;
            font-size: 18px;
            display: block;
        }
    }

}