@import "../../../styles/breakpoints.scss";


.modal_wrapper {
    &.modal_active {
        padding-right: 18px;
        height: 100vh;
        overflow: hidden!important;

        @include lg {
            padding: 0;
        }

    }
}

.candidate_info {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .wrapper {
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include lg {
            width: 98%;
        }

        .error_message {
            color: var(--color-error);
            font-weight: 400!important;
        }

        hr {
            border-top: 1px solid var(--swiper-theme-color);
            border-bottom: 0;
            margin: 12px 0;

            &.separator {
                margin: 24px 0;
            }
        }

        p {
            &.title {
                color: var(--color-purple-dark);
                font-size: 28px;
                font-weight: 700;
            }

            &.form_section_title {
                font-weight: 700;
                font-size: 20px;
                padding: 32px 0;

                &.no_padding_top {
                    padding-top: 0;
                }
            }

            &.form_section_title_2 {
                font-weight: 700;
                font-size: 20px;
            }
        }

        button {
            &.add_info {
                background-color: var(--color-white);
                border: 0;
                padding: 0;
                width: fit-content;
                display: flex;
                gap: 8px;
                justify-content: center;
                align-items: center;
                color: var(--color-secondary-light);
                font-size: 16px;
                font-weight: 700;

                &.padded {
                    padding-top: 32px;
                }

                &.idle {
                    padding-top: 32px;
                    color: var(--color-purple-dark);

                    svg {
                        path {
                            fill: var(--color-purple-dark);
                        }
                    }
                }

            }
        }

        label {
            font-weight: 700;

            &.subtitle {
                font-weight: 400;
            }
        }

        .candidate_columns {
            display: flex;
            //justify-content: space-between;
            gap: 24px;

            .candidate_form {
                width: 70%;
                padding: 40px 0;

                .image_container {
                    width: 100%;
                    padding: 48px 0;
                    display: flex;
                    justify-content: center;
                    border: 1px solid var(--color-border-gray);
                    border-radius: 8px;
                    margin-top: 32px;
                    cursor: pointer;

                    button {
                        border: 0;
                        background-color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .image_circle {
                        width: 150px;
                        height: 150px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .image_text {
                        display: flex;
                        gap: 6px;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 600;
                        padding-top: 24px;
                        color: var(--color-secondary-light);
                    }

                    &.error {
                        border-color: var(--color-error-ligth);
                    }

                }

                .form_container {
                    
                    .form_wrapper {
                        padding-top: 32px;

                        .form_columns {
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 36px;

                            .form_column {
                                width: 45%;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                button {
                                    border: 1px solid var(--color-border-gray);
                                    background-color: var(--color-white);
                                    display: flex;
                                    justify-content: space-between;
                                    height: 50px;
                                    border-radius: 5px;
                                    padding: 0 16px;
                                    align-items: center;
                                    position: relative;

                                    &.error {
                                        border-color: var(--color-error);
                                    }

                                    .countries_list{
                                        top: 49px;
                                        left: 0px;
                                        position: absolute;
                                        height: 150px;
                                        display: none;
                                        overflow-y: auto;
                                        z-index: 100;
                                        background-color: var(--color-white);
                                        border: 1px solid var(--color-border-gray);
                                        border-radius: 5px;
                                        width: 99%;

                                        &.active {
                                            display: block;
                                        }

                                        .country_container {
                                            display: flex;
                                            padding: 4px 16px;
                                            gap: 8px;
                                            font-weight: 400;
                                            font-size: 16px;
                                        }
                                    }

                                    .placeholder_text {
                                        color: var(--color-border-gray);
                                    }
                                }
                            }
                        }

                        .form_columns_line {
                            display: flex;
                            padding-bottom: 8px;
                            gap: 40px;

                            &.top_padded {
                                padding-top: 40px;
                            }

                            &.top_padded_short {
                                padding-top: 12px;
                            }

                            .form_column {
                                width: 45%;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .form_column_line {
                                    display: flex;
                                    gap: 8px;

                                    input, select {
                                        width: -webkit-fill-available;
                                    }
                                }
                            }

                            .form_columns_line_2 {
                                display: flex;
                                padding-bottom: 8px;
                                gap: 8px;
                            }
                        }

                        .form_row {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            &.no_gap {
                                gap: 0px;
                            }

                            &.padded {
                                padding: 32px 0;
                            }

                            &.padded_short {
                                padding: 8px 0;
                            }
                            
                            &.top_padded {
                                padding-top: 32px;
                            }

                            .text_area_container {
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                &.error {

                                    textarea {
                                        border-color: var(--color-error);
                                    }
                                }

                                p {
                                    &.error {
                                        color: var(--color-error);
                                    }
                                }

                                .text_lenght_container {
                                    display: flex;
                                    justify-content: flex-end;

                                    &.error {
                                        justify-content: space-between;
                                    }
                                }

                            }
                        }

                        input {
                            border-radius:  5px;
                            border: 1px solid var(--color-border-gray);
                            height: 50px;
                            padding: 0 16px;
                            box-sizing: border-box;

                            &.file_input {
                                display: none;
                            }

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        select {
                            border-radius:  5px;
                            border: 1px solid var(--color-border-gray);
                            height: 50px;
                            padding: 0 16px;
                            box-sizing: border-box;
                        }

                        textarea {
                            resize: none;
                            border: 1px solid var(--color-border-gray);
                            padding: 16px;
                            box-sizing: border-box;
                            border-radius: 8px;
                            width: 100%;
                        }
                    }

                    .form_information {
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        padding-top: 40px;

                        .title {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .information_container {
                            display: flex;
                            justify-content: left;
                            gap: 20px;

                            .information_level_container {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .information_level {
                                    border-radius: 6px;
                                    background: var(--color-purple-dark);
                                    color: white;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    width: 45px;
                                    height: 45px;
                                    line-height: 50px;
                                    text-align: center;
                                }
                            }

                            .information_title {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                            }

                            .information_desc {
                                text-align: justify;
                            }
                        }
                    }
                }

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    gap: 32px;

                    button {
                        width: 330px;
                        background-color: var(--color-secondary-light);
                        border: 0;
                        color: var(--color-white);
                        border-radius: 8px;
                        padding: 16px 0;
                        font-weight: 700;
                        font-size: 16px;

                        &.cancel_button {
                            width: max-content;
                            min-width: 220px;
                            padding: 0 80px;
                            border: 2px solid var(--color-purple-dark);
                            background-color: var(--color-white);
                            color: var(--color-purple-dark);
                        }
                    }
                }

            }
    
            .roadmap {
                width: 22%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 80px 94px;
                background: #FFF4F2;

                .roadmap_step {
                    display: flex;
                    justify-content: flex-start;
                    gap: 12px;
                    position: relative;

                    > p {
                        flex: 1;
                        color: var(--color-purple-dark);

                        &.active {
                            color: var(--color-secondary-light);
                            font-weight: 700;
                        }
                    }

                    .separator {
                        width: 1px;
                        height: 14px;
                        position: absolute;
                        bottom: 21px;
                        left: 10px;
                        background-color: var(--color-purple-dark);

                        &.active {
                            background-color: var(--color-secondary-light);
                        }

                    }

                    .bullet_icon {
                        width: 8%;
                        display: flex;
                        justify-content: center;
                        

                        .idle {
                            color: var(--color-purple-dark);
                            width: 13px;
                        }

                        .active {
                            color: var(--color-secondary-light);
                            font-weight: 300;
                        }

                    }

                    .icon_container {
                        width: 10%;
                        display: none;

                        &.visible {
                            display: block;
                        }
                    }
                }
            }
        }

        .candidate_resume {
            padding: 88px 0;

            .mobile_separator {
                display: none;
                border-color: var(--color-secondary-light);
                border-width: 3px;

                @include lg {
                    display: block;
                }

            }

            .content_wrapper {
                padding: 0 24px;
            }

            .section_subtitle {
                padding-top: 64px;
            }

            .section_title {
                font-weight: 600;
                font-size: 20px;
                color: var(--color-purple-dark);
                display: flex;
                align-items: center;
                gap: 12px;

                .decorator {
                    height: 30px;
                    width: 4px;
                    background-color: var(--color-secondary-light);
                    border-radius: 15px
                }

                &.padded {
                    padding-bottom: 38px;
                }
                
            }

            .personal_info {
                padding: 64px 0;
                width: 100%;
                display: flex;
                flex-direction: column;

                .section_header {
                    width: 70%;
                    display: flex;
                    align-items: center;
                    position: relative; 
                    gap: 12px;
                    padding-bottom: 12px;

                    @include lg {
                        width: 100%;
                    }

                    .button_container {
                        display: flex;
                        gap: 16px;
                    }

                    .vertical_separator {
                        height: 30px;
                        width: 2px;
                        background-color: var(--color-border-gray);
                    }
                }

                .personal_info_wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .candidate_contact_info_mobile {
                        width: 100%;
                        padding-bottom: 32px;
                        display: none;
                        gap: 16px;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .contact_field {
                            display: flex;
                            gap: 6px;
                            align-items: center;
                        }

                        @include lg {
                            display: flex;
                        }

                    }

                    .about_candidate {
                        width: 100%;

                        .content {
                            white-space: pre-wrap;
                            word-wrap: break-word;
                        }
                    }

                    .profile_info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        gap: 24px;

                        .candidate_info_columns {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            p {
                                &.candidate_name {
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }
                            
                            .candidate_contact_info {
                                display: flex;
                                gap: 12px;

                                .personal_info_separator {
                                    width: 2px;
                                    height: 25px;
                                    background-color: var(--color-border-gray);
                                }

                                .contact_field {
                                    display: flex;
                                    gap: 6px;
                                    align-items: center;
                                }

                                @include lg {
                                    display: none;
                                }
                            }
                        }
                    }

                    .info_column {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                    }

                    .picture_column {
                        width: fit-content;
                        padding: 24px 0;

                        .candidate_profile_picture {
                            width: 160px;
                            height: 160px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }
                        
                        button {
                            padding: 12px 24px;
                            background-color: var(--color-white);
                            border: 0;
                        }
                    }
                }
            }
            
            .section {
                padding: 42px 0 32px 0;

                .cards_wrapper {
                    display: flex;
                    flex-direction: column;
                }

                .pills_wrapper {
                    display: flex;
                    gap: 12px;
                    padding: 24px 0 12px 0;
                    flex-wrap: wrap;

                    p {
                        padding: 8px 16px;
                        color: var(--color-white);
                        background-color: var(--color-purple-dark);
                        border-radius: 30px;
                    }
                }

                .button_container {
                    display: flex;
                    gap: 16px;
                }

                &_title {
                    color: var(--color-purple-dark);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                padding-top: 32px;

                button {
                    width: 330px;
                    background-color: var(--color-secondary-light);
                    border: 0;
                    color: var(--color-white);
                    border-radius: 8px;
                    padding: 16px 0;
                    font-weight: 700;
                    font-size: 16px;

                    @include lg {
                        width: 100%;
                    }
                }
            }
        }

        .info_card {
            border-radius: 8px;
            padding: 24px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .language_info {

                > label {
                    display: inline;
                }

                > p {
                    display: inline;
                }
            }

            .card_title {
                display: flex;
                justify-content: flex-start;
                gap: 8px;

                @include lg {
                    flex-direction: column;

                    .hidden_mobile {
                        display: none;
                    }
                }

            }
        }

        .backbutton {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            width: 90px;
            color: var(--color-purple-dark);
            padding-bottom: 40px;

            span {
                color: var(--color-purple-dark);
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
            }
        }

    }
}

.list_container {
    border-radius:  5px;
    border: 1px solid #B5B5B5;
    margin-top: 16px;
    margin-bottom: 16px;

    &.top_padded {
        margin-top: 40px;
    }

    .list_items {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 32px;

        .item_grouped {
            display: flex;
            justify-content: space-between;
        }

        .action_container {
            display: flex;
            justify-content: flex-end;
            gap: 5px;
        }

        .certification_info {
            display: flex;
            flex-direction: column;
            gap: 0px;
            padding-top: 8px;

            .info_container {
                display: flex;
                gap: 8px;

                > label {
                    font-weight: 700;
                }
            }
        }
    }
}

.vl {
    background: #A5A5A5;
    width: 1px;
    height: 50px;
    margin-top: 8px;
}

.error_message_font {
    color: var(--color-error-ligth);
    font-weight: 400!important;
    font-size: 14px;
    padding-bottom: 8px;
}

.modal_wrapper_mobile {
    &.modal_active {
        height: 100vh;
        overflow: hidden!important;
    }
}

.candidate_info_mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .wrapper {
        flex-direction: column;
        gap: 12px;

        @include lg {
            width: 98%;
        }

        .error_message {
            color: var(--color-error);
            font-weight: 400!important;
        }

        hr {
            border-top: 1px solid var(--swiper-theme-color);
            border-bottom: 0;
            margin: 12px 0;

            &.separator {
                margin: 24px 0;
            }
        }

        p {
            &.title {
                color: var(--color-purple-dark);
                font-size: 24px;
                font-weight: 700;
            }

            &.form_section_title {
                font-weight: 600;
                font-size: 20px;
                padding-top: 30px;
                padding-bottom: 20px;

                &.no_padding_top {
                    padding-top: 0;
                }
            }
        }

        button {
            &.add_info {
                background-color: var(--color-white);
                border: 0;
                padding: 0;
                width: fit-content;
                display: flex;
                gap: 8px;
                justify-content: center;
                align-items: center;
                color: var(--color-secondary-light);
                font-size: 16px;
                font-weight: 700;

                &.padded {
                    padding-top: 32px;
                }

                &.idle {
                    padding-top: 32px;
                    color: var(--color-purple-dark);

                    svg {
                        path {
                            fill: var(--color-purple-dark);
                        }
                    }
                }

            }
        }

        label {
            color: var(--color-primary);
            font-size: 16px;
            font-weight: 600;

            &.subtitle {
                font-weight: 400;
            }
        }

        .roadmap {
            height: 77px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .roadmap_title {
                display: flex;
                justify-content: flex-start;
                padding-left: 24px;

                > p {
                    color: var(--color-purple-dark);
                    font-size: 20px;
                    font-weight: 600;

                    &.active {
                        color: var(--color-secondary-light);
                        font-weight: 700;
                    }
                }
            }
        }

        .roadmap_step {
            height: 3px;
            background-color: var(--color-steper-mobile);

            .progress {
                height: 3px;
                background: var(--color-secondary-light);
            }
        }

        .candidate_columns {
            display: flex;
            justify-content: space-between;
            // gap: 64px;

            .candidate_form {
                padding: 30px 24px;

                .image_container {
                    width: 100%;
                    padding: 28px 0;
                    display: flex;
                    justify-content: center;
                    border: 1px solid var(--color-border-gray);
                    border-radius: 26px;
                    margin-top: 32px;
                    cursor: pointer;

                    button {
                        border: 0;
                        background-color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .image_circle {
                        width: 150px;
                        height: 150px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .image_text {
                        display: flex;
                        gap: 6px;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 600;
                        padding-top: 24px;
                        color: var(--color-secondary-light);
                    }

                    &.error {
                        border-color: var(--color-error-ligth);
                    }
                }

                .form_container {
                    
                    .form_wrapper {
                        padding: 32px 0;

                        .form_columns {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 30px;
                            padding-bottom: 30px;

                            .form_column {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                button {
                                    border: 1px solid var(--color-border-gray);
                                    background-color: var(--color-white);
                                    display: flex;
                                    justify-content: space-between;
                                    height: 50px;
                                    border-radius: 5px;
                                    padding: 0 16px;
                                    align-items: center;
                                    position: relative;

                                    &.error {
                                        border-color: var(--color-error);
                                    }

                                    .countries_list{
                                        top: 49px;
                                        left: 0px;
                                        position: absolute;
                                        height: 150px;
                                        display: none;
                                        overflow-y: auto;
                                        z-index: 100;
                                        background-color: var(--color-white);
                                        border: 1px solid var(--color-border-gray);
                                        border-radius: 5px;
                                        width: 99%;

                                        &.active {
                                            display: block;
                                        }

                                        .country_container {
                                            display: flex;
                                            padding: 4px 16px;
                                            gap: 8px;
                                            font-weight: 400;
                                            font-size: 16px;
                                        }
                                    }

                                    .placeholder_text {
                                        color: var(--color-border-gray);
                                    }
                                }
                            }
                        }

                        .form_columns_line {
                            display: flex;
                            padding-bottom: 8px;
                            gap: 20px;

                            &.top_padded {
                                padding-top: 40px;
                            }

                            &.top_padded_short {
                                padding-top: 12px;
                            }

                            .form_column {
                                width: 45%;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .form_column_line {
                                    display: flex;
                                    gap: 4px;

                                    input, select {
                                        width: -webkit-fill-available;
                                    }
                                }
                            }

                            .form_columns_line_2 {
                                display: flex;
                                padding-bottom: 8px;
                                gap: 8px;
                            }
                        }

                        .form_row {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            &.no_gap {
                                gap: 0px;
                            }

                            &.padded {
                                padding: 16px 0;
                            }

                            &.padded_short {
                                padding: 8px 0;
                            }
                            
                            &.top_padded {
                                padding-top: 32px;
                            }

                            .text_area_container {
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                &.error {

                                    textarea {
                                        border-color: var(--color-error);
                                    }
                                }

                                p {
                                    &.error {
                                        color: var(--color-error);
                                    }
                                }

                                .text_lenght_container {
                                    display: flex;
                                    justify-content: flex-end;

                                    &.error {
                                        justify-content: space-between;
                                    }
                                }

                            }
                        }

                        input {
                            border-radius:  5px;
                            border: 1px solid var(--color-border-gray);
                            height: 50px;
                            padding: 0 16px;
                            box-sizing: border-box;

                            &.file_input {
                                display: none;
                            }

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        select {
                            border-radius:  5px;
                            border: 1px solid var(--color-border-gray);
                            height: 50px;
                            padding: 0 16px;
                            box-sizing: border-box;
                        }

                        textarea {
                            resize: none;
                            border: 1px solid var(--color-border-gray);
                            padding: 16px;
                            box-sizing: border-box;
                            border-radius: 8px;
                            width: 100%;
                            height: 140px;
                        }
                    }

                    .form_information {
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        padding-top: 40px;

                        .title {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .information_container {
                            display: flex;
                            justify-content: left;
                            gap: 20px;

                            .information_level_container {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .information_level {
                                    border-radius: 6px;
                                    background: var(--color-purple-dark);
                                    color: white;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    width: 45px;
                                    height: 45px;
                                    line-height: 50px;
                                    text-align: center;
                                }
                            }

                            .information_title {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                            }

                            .information_desc {
                                text-align: justify;
                            }
                        }
                    }
                }

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    button {
                        background-color: var(--color-secondary-light);
                        border: 0;
                        color: var(--color-white);
                        border-radius: 8px;
                        font-weight: 700;
                        font-size: 16px;
                        height: 56px;

                        &.cancel_button {
                            border: 2px solid var(--color-purple-dark);
                            background-color: var(--color-white);
                            color: var(--color-purple-dark);
                            height: 56px;
                        }
                    }
                }

            }
        }

        .candidate_resume {

            .mobile_separator {
                display: none;
                border-color: var(--color-secondary-light);
                border-width: 3px;

                @include lg {
                    display: block;
                    margin: 0;
                }

            }

            .section_subtitle {
                padding-top: 64px;

                @include lg {
                    display: none;
                }
            }

            .section_title {
                font-weight: 600;
                font-size: 20px;
                color: var(--color-purple-dark);
                display: flex;
                align-items: center;
                gap: 12px;

                .decorator {
                    height: 30px;
                    width: 4px;
                    background-color: var(--color-secondary-light);
                    border-radius: 15px
                }

                &.padded {
                    padding-bottom: 38px;
                }
                
            }

            .personal_info {
                padding: 32px 0;
                width: 100%;
                display: flex;
                flex-direction: column;

                .section_header {
                    width: 70%;
                    display: flex;
                    align-items: center;
                    position: relative; 
                    gap: 12px;
                    padding-bottom: 12px;

                    .button_container {
                        display: flex;
                        gap: 16px;
                    }

                    @include lg {
                        width: 100%;
                        justify-content: space-between;
                    }

                    .vertical_separator {
                        height: 30px;
                        width: 2px;
                        background-color: var(--color-border-gray);
                    }
                }

                .personal_info_wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .candidate_contact_info_mobile {
                        width: 100%;
                        padding-bottom: 16px;
                        display: none;
                        gap: 8px;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .contact_field {
                            display: flex;
                            gap: 6px;
                            align-items: center;
                        }

                        .personal_info_separator {
                            width: 2px;
                            height: 25px;
                            background-color: var(--color-border-gray);
                        }

                        @include lg {
                            display: flex;
                        }

                    }

                    .about_candidate {
                        width: 100%;

                        .content {
                            white-space: pre-wrap;
                            word-wrap: break-word;
                        }
                    }

                    .profile_info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        gap: 24px;

                        @include lg {
                            align-items: flex-start;
                            padding: 24px 0;
                        }

                        .candidate_info_columns {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            @include lg {
                                padding: 8px 0;
                                gap: 2px;
                            }

                            p {
                                &.candidate_name {
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }
                            
                            .candidate_contact_info {
                                display: flex;
                                gap: 12px;


                                .personal_info_separator {
                                    width: 2px;
                                    height: 25px;
                                    background-color: var(--color-border-gray);
                                }

                                .contact_field {
                                    display: flex;
                                    gap: 6px;
                                    align-items: center;
                                }

                                @include lg {
                                    display: none;
                                }

                            }
                        }
                    }

                    .info_column {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                    }

                    .picture_column {
                        width: fit-content;
                        padding: 24px 0;

                        @include lg {
                            padding: 0;
                        }

                        .candidate_profile_picture {
                            width: 160px;
                            height: 160px;

                            @include lg {
                                width: 130px;
                                height: 130px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }
                        
                        button {
                            padding: 12px 24px;
                            background-color: var(--color-white);
                            border: 0;
                        }
                    }
                }
            }
            
            .section {
                padding: 32px 0;

                @include lg {
                    padding: 4px 0;
                }

                .cards_wrapper {
                    display: flex;
                    flex-direction: column;
                }

                .pills_wrapper {
                    display: flex;
                    gap: 12px;
                    padding: 24px 0 12px 0;
                    flex-wrap: wrap;

                    p {
                        padding: 8px 16px;
                        color: var(--color-white);
                        background-color: var(--color-purple-dark);
                        border-radius: 30px;
                    }
                }

                .button_container {
                    display: flex;
                    gap: 8px;
                    padding-top: 8px;
                }

                &_title {
                    color: black;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    padding-top: 16px;
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                padding-top: 32px;

                button {
                    width: 330px;
                    background-color: var(--color-secondary-light);
                    border: 0;
                    color: var(--color-white);
                    border-radius: 8px;
                    padding: 16px 0;
                    font-weight: 700;
                    font-size: 16px;

                    @include lg {
                        width: 100%;
                    }
                }
            }
        }

        .info_card {
            border-radius: 8px;
            padding: 24px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include lg {
                padding: 8px;
                gap: 0;
            }

            .card_title {
                display: flex;
                justify-content: flex-start;
                gap: 8px;

                @include lg {
                    flex-direction: column;
                    gap: 0;

                    .hidden_mobile {
                        display: none;
                    }

                    .subtitle {
                        font-weight: 500;
                    }
                }
            }
        }

        .backbutton {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            color: var(--color-purple-dark);

            span {
                color: var(--color-purple-dark);
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
            }
        }

    }
}

.list_container {
    border-radius:  5px;
    border: 1px solid #B5B5B5;
    margin-top: 16px;
    margin-bottom: 16px;

    &.top_padded {
        margin-top: 30px;
    }

    .list_items {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px;

        .item_grouped {
            display: flex;
            justify-content: space-between;
        }

        .action_container {
            display: flex;
            justify-content: flex-end;
            gap: 5px;
        }
    }
}

.error_message_font {
    color: var(--color-error-ligth);
    font-weight: 400!important;
    font-size: 14px;
    padding-bottom: 8px;
}

.message_wrapper {
    position: relative;
    padding: 88px 0;
    font-weight: 700;
    text-align: left;

    @include lg {
        text-align: center;
        padding: 40px 0;
    }

    .title_wrapper {
        width: 60%;
        font-size: 40px;

        @include lg {
            font-size: 24px;
            width: 100%;
        }

        p {
            background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .web {
            @include lg {
                display: none;
            }
        }

        .mobile {
            display: none;

            @include lg {
                display: block;
            }
        }
    }

    .message_content {
        padding: 24px 0;
        font-size: 22px;
        width: 60%;

        @include lg {
            width: 100%;
            font-size: 18px;
        }

        p {
            @include lg {
                padding: 0 32px;
            }
        }

        .instruction {
            @include lg {
                display: none;
            }

            &_mobile {
                display: none;

                @include lg {
                    display: block;
                }
            }
        }

        .buttons {
            display: flex;
            gap: 32px;
            padding: 32px 0;

            @include lg {
                flex-direction: column;
                align-items: center;
            }

            button {
                width: 330px;
                background-color: var(--color-secondary-light);
                border: 0;
                color: var(--color-white);
                border-radius: 8px;
                padding: 16px 0;
                font-weight: 700;
                font-size: 16px;

                &.iddle {
                    background-color: var(--color-white);
                    color: var(--color-purple-dark);
                    border: 2px solid var(--color-purple-dark);
                }
            }
        }

        .links {
            .links_row {
                display: flex;
                gap: 16px;
                padding-top: 32px;

                @include lg {
                    justify-content: center;
                }

                .icon_container {
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 50%;
                    width: 64px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        height: 24px;

                        svg {
                            path {
                                fill: var(--color-purple-dark);
                            }
                        }
                    }
                }
            }
        }
    }

    img {
        position: absolute;
        right: -10%;
        bottom: 0;
        max-width: 632px;
        max-height: 590px;
        width: auto;
        height: auto;
        z-index: -1;

        @include lg {
            display: none;
        }
    }
}

.loading_container {
    background-color: rgba(48, 48, 48, 0.45);
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;
    z-index: 2;

    .spinner_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 35vh;

        p {
            font-size: 30px;
            font-weight: 600;
            color: var(--color-white);
        }
    
        .spinner {
            border: 5px solid var(--color-white);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: var(--color-spinner);
          
            animation: spin 1s ease infinite;
        }
          
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            
            100% {
                transform: rotate(360deg);
            }
        }

    }
}

.no_scroll {
    overflow: hidden;
}

.flex_14gap{
    display: flex;
    gap: 14px;
    align-items: flex-end;
}

.error_boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    gap: 8px;

    .font {
        color: #3F5073;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
    }

    .info {
        font-weight: 400;
    }

    .email {
        color: #109AE8;
    }
}