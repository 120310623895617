@import "../../../styles/breakpoints.scss";

.modal_base {
    height: 100vh;
    width: 100%;
    position: absolute;
    background-color: var(--color-modal-background);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    &.full {
        min-height: 100vh;
        width: 100%;
        overflow-y: auto;

        @include lg {
            display: block;
            position: relative;
        }
    }

    .stop_propagation {
        display: flex;
        justify-content: center;
        align-items: center;

        @include lg {
            width: 100%;
        }
    }

    .stop_propagation_scroll {
        position: fixed;
        top: 4vh;
        height: 97%;
        justify-content: center;
        align-items: center;
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 0px;
        }

        @include lg {
            display: flex;
        }
    }
}

.modal_container {
    width: 982px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid black;
    background: var(--color-white);

    @include lg {
        min-height: 100vh;
        padding: 42px 0;
        width: 100%;
        border: 0;
    }

    .close {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        padding-top: 32px;
        padding-left: 918px;
        padding-right: 32px;

        @include lg {
            display: none;
        }

        img {
            cursor: pointer;
        }
    }

    .title {
        color: var(--color-purple-dark);
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 60px;

        @include lg {
            padding: 20px;
        }
    }

    label {
        font-weight: 700;

        &.subtitle {
            font-weight: 400;
        }

        &.subtitle_left {
            font-weight: 400;
            text-align: end;
        }
    }

    .form_container {
        justify-content: space-between;
        padding: 0 70px;

        @include lg {
            padding: 0 20px;
        }
        
        .form_wrapper {
            padding: 32px 0;

            .form_subtitle {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 18px;
                color: var(--color-purple-dark);
            }

            .form_columns {
                display: flex;
                justify-content: space-between;
                padding-bottom: 24px;

                @include lg {
                    flex-direction: column;
                    gap: 36px;
                }

                .form_column {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    @include lg {
                        width: 100%;
                    }

                    .custom_input {
                        input {
                            width: 100%;

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        > p {
                            margin-bottom: 8px;
                        }

                        .custom_title {
                            font-weight: 700;
                        }

                        .error_message {
                            font-size: 14px;
                            color: var(--color-error);
                            margin: 0;
                        }

                    }

                    .custom_select {
                        position: relative;

                        > p {
                            margin-bottom: 8px;
                        }

                        .custom_title {
                            font-weight: 700;
                        }

                        .select_container {
                            box-sizing: border-box;
                            display: flex;
                            width: 100%;
                            border: 1px solid var(--color-border-gray);
                            border-radius: 5px;
                            height: 50px;
                            padding: 0 16px;
                            align-items: center;
                            justify-content: space-between;

                            svg {
                                cursor: pointer;
                            }

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        .select_options {
                            position: absolute;
                            width: 100%;
                            box-sizing: border-box;
                            border: 1px solid var(--color-border-gray);
                            border-radius: 5px;
                            z-index: 10;
                            background-color: var(--color-white);
                            display: none;

                            &.visible {
                                display: block;
                            }

                            p {
                                padding: 4px 16px;
                                cursor: pointer;

                                &:hover {
                                    background-color: var(--color-table-row-selected);
                                }
                            }

                        }

                        .error_message {
                            font-size: 14px;
                            color: var(--color-error);
                            margin: 0;
                        }
                    }
                }
            }

            .form_row {
                display: flex;
                flex-direction: column;
                gap: 8px;

                &.no_gap {
                    gap: 0px;
                }

                &.padded {
                    padding: 32px 0;
                }
                
                &.top_padded {
                    padding-top: 32px;
                }
            }

            input {
                border-radius:  5px;
                border: 1px solid var(--color-border-gray);
                height: 50px;
                padding: 0 16px;
                box-sizing: border-box;
            }

            select {
                border-radius:  5px;
                border: 1px solid var(--color-border-gray);
                height: 50px;
                padding: 0 16px;
                box-sizing: border-box;
            }

            textarea {
                border-radius:  5px;
                resize: none;
                border: 1px solid var(--color-border-gray);
                padding: 16px;
                box-sizing: border-box;

                @include lg {
                    height: 150px;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            gap: 32px;
            padding-bottom: 32px;

            @include lg {
                width: 100%;
                padding: 0;
                justify-content: space-between;
            }


            button {
                width: 228px;
                background-color: var(--color-secondary-light);
                border: 0;
                color: var(--color-white);
                border-radius: 8px;
                padding: 10px 80px;
                font-weight: 700;
                font-size: 16px;

                &.cancel_button {
                    min-width: 220px;
                    padding: 0 32px;
                    border: 2px solid var(--color-purple-dark);
                    background-color: var(--color-white);
                    color: var(--color-purple-dark);

                    &.mobile {
                        display: none;

                        @include lg {
                            display: block;
                        }

                    }

                    @include lg {
                        min-width: auto;
                        width: 45%;
                        padding: 12px 0;
                    }
                }

                @include lg {
                    width: 45%;
                    padding: 0;
                }

            }
        }

    }

}

.modal_delete {
    width: 474px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px;
    background: var(--color-white);
    padding: 40px;

    @include lg {
        width: 80%;
        position: fixed;
        top: 13.5vh;
        padding: 2.4vh 1.6vh;
    }

    .title {
        color: var(--color-purple-dark);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .subtitle {
        font-weight: 400;
        padding: 24px 0;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 23px;

        button {
            width: 180px;
            background-color: var(--color-secondary-light);
            border: 0;
            color: var(--color-white);
            border-radius: 8px;
            padding: 10px;
            font-weight: 700;
            font-size: 16px;

            &.cancel_button {
                width: 180px;
                padding: 10px;
                border: 2px solid var(--color-purple-dark);
                background-color: var(--color-white);
                color: var(--color-purple-dark);
            }
        }
    }
}

.modal_verification_code {
    width: 600px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px;
    background: var(--color-white);
    padding: 40px;

    @include lg {
        width: 70%;
        position: fixed;
        top: 13.5vh;
    }

    .title {
        color: var(--color-purple-dark);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .subtitle {
        font-weight: 400;
        padding: 24px 0;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 23px;

        @include lg {
            width: 100%;
            padding: 0;
            justify-content: space-between;
        }

        button {
            background-color: var(--color-secondary-light);
            border: 0;
            color: var(--color-white);
            border-radius: 8px;
            padding: 10px 24px;
            font-weight: 700;
            font-size: 16px;

            &.cancel_button {
                padding: 0 80px;
                border: 2px solid var(--color-purple-dark);
                background-color: var(--color-white);
                color: var(--color-purple-dark);

                @include lg {
                    min-width: auto;
                    width: 45%;
                    padding: 12px 0;
                }
            }

            @include lg {
                width: 45%;
                padding: 0;
            }
        }
    }
}