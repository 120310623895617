@import "../../../styles/breakpoints.scss";

.modal_wrapper {
    &.modal_active {
        padding-right: 18px;
        height: 100vh;
        overflow: hidden!important;
    }
}

.modal_wrapper_mobile {
    &.modal_active {
        height: 100vh;
        overflow: hidden!important;
    }
}

.error_modal {
    background-color: var(--color-white);
    padding: 32px;
    border-radius: 8px;

    @include lg {
        width: 70%;
        position: fixed;
        top: 13.5vh;
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        color: var(--color-purple-dark);
    }

    .buttons {
        display: flex;
        justify-content: flex-end;

        button {
            color: var(--color-white);
            font-weight: 600;
            background-color: var(--color-secondary-light);
            border: 0;
            padding: 12px;
            border-radius: 8px;
            width: 200px;
        }
    }
}

.pass_recovery {
    background: linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%);
    display: flex;
    justify-content: center;
    position: relative;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 65%;

        p {
            &.title {
                background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 40px;
                font-weight: 700;
            }
        }

        .instructions {
            padding: 54px 0;
            width: 55%;
            display: flex;
            flex-direction: column;
            gap: 24px;
            font-size: 24px;
        }

        .recovery_form_wrapper {
            padding: 64px 0;

            .image_column {
                width: 55%;
                position: relative;

                img {
                    display: block;
                    max-width: 699px;
                    max-height: 700px;
                    width: auto;
                    height: auto;
                    position: absolute;
                    right: -570px;
                    bottom: -65px;
                    z-index: -1;
                }
            }
        }

        .recovery_button {
            display: flex;
            justify-content: center;

            > button {
                width: 100%;
            }
        }

        button {
            color: var(--color-purple-dark);
            font-weight: 600;
            background-color: var(--color-white);
            border: 1px solid var(--color-purple-dark);
            padding: 12px;
            border-radius: 8px;
            width: 300px;

            &.active {
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;
            }
        }

        form {
            background: white;
            width: 46%;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 32px;

            hr {
                width: 100%;
            }

            a {
                color: var(--color-secondary-light);
                font-weight: 700;
            }

            .centered_field {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 700;

                .error_message {
                    font-weight: 400;
                }
            }

            .error_message {
                color: var(--color-error);
            }

            .form_field {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .password_container {
                    display: flex;
                    gap: 12px;
                    height: 50px;
                    border: 1px solid var(--color-border-gray);
                    background-color: var(--color-white);
                    border-radius: 5px;
                    padding: 0 16px;
                    
                    &.error {
                        border-color: var(--color-error);
                    }

                    button {
                        background-color: var(--color-white);
                        border: 0;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        padding: 0;
                    }

                    input {
                        border: 0;
                        padding: 0;
                    }

                    svg {
                        width: 32px;
                        
                        path {
                            fill: var(--color-password-icon)
                        }
                    }

                }

                label {
                    font-weight: 700;
                }

                input {
                    width: 100%;
                    height: 50px;
                    border: 1px solid var(--color-border-gray);
                    border-radius: 5px;
                    padding: 0 16px;
                    box-sizing: border-box;

                    &.error {
                        border-color: var(--color-error);
                    }
                }
            }

            &.password_recovery {
                border: 1px solid var(--color-border-gray);
                padding: 48px;
                width: 28%;
                margin-top: 24px;
            }
        }

        .verification_wrapper {
            padding: 62px;
            
            .instructions_wrapper {
                padding-top: 32px;
                padding-bottom: 64px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                position: relative;

                &.small_gap {
                    gap: 4px;
                }

                .password_validator {
                    background-color: white;
                    position: absolute;
                    left: 50%;
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 18px;
                    padding: 16px 32px;
                    flex-direction: column;
                    gap: 10px;
                    display: none;

                    p {
                        font-size: 15px;
                    }
                    
                    .validator_title {
                        font-weight: 600;
                    }

                    &.active {
                        display: flex;
                    }

                    .validator_row {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        &.validated {
                            svg {
                                circle {
                                    fill: var(--color-secondary-light);
                                }
                            }
                        }

                        &.error {
                            color: var(--color-error);
                        }
                    }

                    .box_notch {
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        bottom: 65%;
                        left: -9px;
                        z-index: 10;
                        border-bottom: 1px solid var(--color-purple-dark);
                        border-left: 1px solid var(--color-purple-dark);;
                        transform: rotate(45deg);
                        background-color: var(--color-white);
                    }

                }
            }

            .resend_button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                background-color: var(--color-white);
                border: 0;
            }

            .buttons_wrapper {
                display: flex;
                gap: 32px;
            }

            .code_wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 16px;

                .code_label {
                    font-weight: 700;
                }

                .code_row {
                    display: flex;
                    gap: 12px;

                    input {
                        width: 70px;
                        height: 70px;
                        text-align: center;
                        font-size: 40px;
                        border-radius: 8px;
                        border: 1px solid var(--color-border-gray);

                        &.error {
                            border-color: var(--color-error);
                        }

                    }
                }
            }

            p {
                &.error_message {
                    color: var(--color-error);
                }
            }
        }
    }
}

.pass_recovery_mobile {
    background: linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%);
    padding: 0 24px;
    display: flex;
    position: relative;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
            &.title {
                background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 24px;
                font-weight: 700;
            }
        }

        .instructions {
            display: flex;
            flex-direction: column;
            padding: 24px 0;
            gap: 16px;
            font-size: 18px;
        }

        .recovery_form_wrapper {
            padding: 40px 0;
        }

        .recovery_button {
            display: flex;
            justify-content: center;

            > button {
                width: 100%;
            }
        }

        button {
            color: var(--color-purple-dark);
            font-weight: 600;
            background-color: var(--color-white);
            border: 1px solid var(--color-purple-dark);
            padding: 12px;
            border-radius: 8px;
            width: 100%;

            &.active {
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;
            }
        }

        form {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 32px;

            hr {
                width: 100%;
            }

            a {
                color: var(--color-secondary-light);
                font-weight: 700;
            }

            .centered_field {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 700;

                .error_message {
                    font-weight: 400;
                }
            }

            .error_message {
                color: var(--color-error);
            }

            .form_field {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .password_container {
                    display: flex;
                    gap: 12px;
                    height: 50px;
                    border: 1px solid var(--color-border-gray);
                    background-color: var(--color-white);
                    border-radius: 5px;
                    padding: 0 16px;
                    
                    &.error {
                        border-color: var(--color-error);
                    }

                    button {
                        background-color: var(--color-white);
                        border: 0;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        padding: 0;
                    }

                    input {
                        border: 0;
                        padding: 0;
                        font-size: 16px;
                    }

                    svg {
                        width: 32px;
                        
                        path {
                            fill: var(--color-password-icon)
                        }
                    }

                }

                label {
                    font-weight: 700;
                }

                input {
                    width: 100%;
                    height: 50px;
                    border: 1px solid var(--color-border-gray);
                    border-radius: 5px;
                    padding: 0 16px;
                    box-sizing: border-box;

                    &.error {
                        border-color: var(--color-error);
                    }
                }
            }

            &.password_recovery {
                border: 1px solid var(--color-border-gray);
                padding: 30px 20px;
                margin-top: 24px;
            }
        }

        .verification_wrapper {
            padding: 40px 0;
            
            .instructions_wrapper {
                padding: 26px 0;
                display: flex;
                flex-direction: column;
                gap: 16px;
                position: relative;

                &.small_gap {
                    gap: 4px;
                }

                .password_validator {
                    flex-direction: column;
                    gap: 10px;
                    display: none;

                    p {
                        font-size: 15px;
                    }
                    
                    .validator_title {
                        font-weight: 600;
                    }

                    &.active {
                        display: flex;
                    }

                    .validator_row {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        &.validated {
                            svg {
                                circle {
                                    fill: var(--color-secondary-light);
                                }
                            }
                        }
                    }

                }
            }

            .resend_button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                background-color: transparent;
                border: 0;
            }

            .buttons_wrapper {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                gap: 12px;
            }

            .code_wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 4px;

                .code_label {
                    font-weight: 700;
                }

                .code_row {
                    display: flex;
                    gap: 6px;

                    input {
                        width: 15%;
                        height: 56px;
                        text-align: center;
                        font-size: 24px;
                        border-radius: 8px;
                        border: 1px solid var(--color-border-gray);

                        &.error {
                            border-color: var(--color-error);
                        }

                    }
                }
            }

            p {
                &.error_message {
                    color: var(--color-error);
                }
            }
        }
    }
}