@import "../../../styles/breakpoints.scss";

.modal_wrapper {
    &.modal_active {
        padding-right: 18px;
        height: 100vh;
        overflow: hidden!important;
    }
}

.modal_wrapper_mobile {
    &.modal_active {
        height: 100vh;
        overflow: hidden!important;
    }
}

.error_modal {
    background-color: var(--color-white);
    padding: 32px;
    border-radius: 8px;

    @include lg {
        width: 70%;
        position: fixed;
        top: 13.5vh;
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        color: var(--color-purple-dark);
    }

    .buttons {
        display: flex;
        justify-content: flex-end;

        button {
            color: var(--color-white);
            font-weight: 600;
            background-color: var(--color-secondary-light);
            border: 0;
            padding: 12px;
            border-radius: 8px;
            width: 200px;
        }
    }

}

.signup {
    background: linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%);
    display: flex;
    justify-content: center;
    position: relative;

    .wrapper {
        width: 65%;
        display: flex;
        flex-direction: column;

        .error_message {
            color: var(--color-error);
            font-weight: 400!important;
        }

        .text_wrapper {
            width: 100%;

            .main_text {
                text-align: left;
                font-size: 40px;
                font-weight: 700;

                p {
                    background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &.login {
                    width: 72%;
                    padding-top: 88px;
                }

            }

        }

        .verification_wrapper {
            padding: 88px 0;
            
            .instructions_wrapper {
                padding: 32px 0 60px;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .resend_button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                border: 0;
                background-color: transparent;
            }

            .buttons_wrapper {
                display: flex;
                padding-top: 60px;
                gap: 32px;

                button {
                    color: var(--color-purple-dark);
                    font-weight: 700;
                    background-color: var(--color-white);
                    border: 1px solid var(--color-purple-dark);
                    padding: 12px;
                    border-radius: 8px;
                    width: 300px;

                    &.active {
                        color: var(--color-white);
                        background-color: var(--color-secondary-light);
                        border: 0;
                    }
                }
            }

            .code_wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 16px;

                .code_label {
                    font-weight: 700;
                }

                .code_row {
                    display: flex;
                    gap: 12px;

                    input {
                        width: 70px;
                        height: 70px;
                        text-align: center;
                        border-radius: 8px;
                        font-size: 40px;
                        border: 1px solid var(--color-border-gray);

                        &.error {
                            border-color: var(--color-error);
                        }

                    }
                }
            }
        }

        .message_wrapper {
            padding-top: 88px;
            padding-bottom: 44px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 64px;

            &.padded_down {
                padding-bottom: 256px;
            }

            .message_title_wrapper {
                width: 70%;
            }

            .instructions_wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 45%;
                font-size: 24px;
            }

            .buttons_wrapper {
                button {
                    width: 250px;
                    border: 0;
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--color-white);
                    background-color: var(--color-secondary-light);
                    border-radius: 8px;
                    padding: 16px 0;
                }
            }

            .message_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50%;
                display: flex;
                align-items: flex-end;

                img {
                    display: block;
                    max-width: 612px;
                    max-height: 590px;
                    width: auto;
                    height: auto;
                    z-index: -1;
                }
            }
        }

        .form_wrapper {
            display: flex;
            justify-content: space-between;
            padding-top: 64px;

            .form_column {
                width: 45%;
                padding-bottom: 64px;
                position: relative;

                form {
                    background-color: white;
                    width: 70%;
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 8px;
                    padding: 32px;

                    .inputs {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;

                        label {
                            font-weight: 700;
                        }

                        input {
                            width: 100%;
                            height: 50px;
                            border: 1px solid var(--color-border-gray);
                            border-radius: 5px;
                            padding: 0 16px;
                            box-sizing: border-box;

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        .input_field {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;

                            .password_container {
                                display: flex;
                                gap: 12px;
                                height: 50px;
                                border: 1px solid var(--color-border-gray);
                                background-color: var(--color-white);
                                border-radius: 5px;
                                padding: 0 16px;
                                
                                &.error {
                                    border-color: var(--color-error);
                                }

                                button {
                                    background-color: var(--color-white);
                                    border: 0;
                                    display: flex;
                                    align-items: center;

                                }

                                input {
                                    border: 0;
                                    padding: 0;
                                }

                                svg {
                                    width: 100%;
                                    
                                    path {
                                        fill: var(--color-password-icon)
                                    }
                                }

                            }
                        }
                    }

                    .buttons {
                        padding-top: 32px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 32px;

                        hr {
                            width: 100%;
                        }

                        a {
                            color: var(--color-secondary-light);
                            font-weight: 700;
                        }

                        button {
                            width: 100%;
                            border: 0;
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--color-white);
                            background-color: var(--color-secondary-light);
                            border-radius: 8px;
                            padding: 16px 0;
                        }

                        .privacy_terms {
                            display: flex;
                            gap: 12px;
                            align-items: flex-start;

                            .lbl_checkbox {
                                display: inline-block;
                                padding: 10px;
                            }

                            input {
                                width: 40px;
                                height: 40px;
                            }

                            p {
                                text-align: left;
                                font-weight: 600;

                                span {
                                    cursor: pointer;
                                    color: var(--color-secondary-light);
                                }
                            }
                        }

                        .login_links {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-weight: 600;

                            a {
                                color: var(--color-secondary-light);
                            }
                        }
                    }
                }

                .password_validator {
                    position: absolute;
                    bottom: 33%;
                    left: 80%;
                    border: 1px solid var(--color-purple-dark);
                    background-color: var(--color-white);
                    border-radius: 18px;
                    padding: 16px 32px;
                    flex-direction: column;
                    gap: 10px;
                    display: none;
                    z-index: 100;
                    width: 260px;

                    p {
                        font-size: 15px;
                    }
                    
                    .validator_title {
                        font-weight: 600;
                    }

                    &.active {
                        display: flex;
                    }

                    .validator_row {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        &.validated {
                            svg {
                                circle {
                                    fill: var(--color-secondary-light);
                                }
                            }
                        }

                        &.error {
                            color: var(--color-error);
                        }
                    }

                    .box_notch {
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        bottom: 65%;
                        left: -9px;
                        z-index: 10;
                        border-bottom: 1px solid var(--color-purple-dark);
                        border-left: 1px solid var(--color-purple-dark);;
                        transform: rotate(45deg);
                        background-color: var(--color-white);
                    }

                }

            }

            .image_login {
                width: 55%;
                position: relative;

                img {
                    display: block;
                    max-width: 709px;
                    max-height: 672px;
                    width: auto;
                    height: auto;
                    z-index: -1;
                }
            }

            .image_signup {
                width: 55%;
                position: relative;

                img {
                    display: block;
                    max-width: 612px;
                    max-height: 623px;
                    width: auto;
                    height: auto;
                    position: absolute;
                    bottom: 0;
                    z-index: -1;
                }
            }

        }
    }

    .wrapper_mobile {
        padding: 40px 24px;
        display: flex;
        flex-direction: column;

        .error_message {
            color: var(--color-error);
            font-weight: 400!important;
        }

        .text_wrapper {
            width: 100%;

            .main_text {
                text-align: left;
                font-size: 24px;
                font-weight: 700;

                p {
                    background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

            }

        }

        .verification_wrapper {
            padding: 88px 0;
            
            .instructions_wrapper {
                padding: 32px 0;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .resend_button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                border: 0;
                background-color: transparent;
            }

            .buttons_wrapper {
                display: flex;
                gap: 32px;

                button {
                    color: var(--color-purple-dark);
                    font-weight: 700;
                    background-color: var(--color-white);
                    border: 1px solid var(--color-purple-dark);
                    padding: 12px;
                    border-radius: 8px;
                    width: 300px;

                    &.active {
                        color: var(--color-white);
                        background-color: var(--color-secondary-light);
                        border: 0;
                    }
                }
            }

            .code_wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 16px;

                .code_label {
                    font-weight: 700;
                }

                .code_row {
                    display: flex;
                    gap: 12px;

                    input {
                        width: 70px;
                        height: 70px;
                        text-align: center;
                        border-radius: 8px;
                        font-size: 40px;
                        border: 1px solid var(--color-border-gray);

                        &.error {
                            border-color: var(--color-error);
                        }

                    }
                }
            }
        }

        .message_wrapper {
            padding-top: 88px;
            padding-bottom: 44px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 64px;

            &.padded_down {
                padding-bottom: 256px;
            }

            .message_title_wrapper {
                width: 70%;
            }

            .instructions_wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 45%;
                font-size: 24px;
            }

            .buttons_wrapper {
                button {
                    width: 250px;
                    border: 0;
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--color-white);
                    background-color: var(--color-secondary-light);
                    border-radius: 8px;
                    padding: 16px 0;
                }
            }

            .message_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50%;
                display: flex;
                align-items: flex-end;

                img {
                    width: 100%;
                }
            }
        }

        .form_wrapper {
            padding-top: 32px;

            .form_column {
                padding-bottom: 64px;
                position: relative;

                form {
                    background-color: white;
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 8px;
                    padding: 30px 20px;

                    .inputs {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 30px;

                        label {
                            font-weight: 700;
                        }

                        input {
                            width: 100%;
                            height: 50px;
                            border: 1px solid var(--color-border-gray);
                            border-radius: 5px;
                            padding: 0 16px;
                            box-sizing: border-box;

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        .input_field {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 12px;

                            .password_container {
                                display: flex;
                                gap: 12px;
                                height: 50px;
                                border: 1px solid var(--color-border-gray);
                                background-color: var(--color-white);
                                border-radius: 5px;
                                padding: 0 16px;
                                
                                &.error {
                                    border-color: var(--color-error);
                                }

                                button {
                                    background-color: var(--color-white);
                                    border: 0;
                                    display: flex;
                                    align-items: center;

                                }

                                input {
                                    border: 0;
                                    padding: 0;
                                }

                                svg {
                                    width: 100%;
                                    
                                    path {
                                        fill: var(--color-password-icon)
                                    }
                                }

                            }
                        }
                    }

                    .buttons {
                        padding-top: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 30px;

                        hr {
                            width: 100%;
                        }

                        a {
                            color: var(--color-secondary-light);
                            font-weight: 700;
                        }

                        button {
                            width: 100%;
                            border: 0;
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--color-white);
                            background-color: var(--color-secondary-light);
                            border-radius: 8px;
                            padding: 16px 0;
                        }

                        .privacy_terms {
                            display: flex;
                            gap: 12px;
                            align-items: flex-start;

                            .lbl_checkbox {
                                display: inline-block;
                                padding: 10px;
                            }

                            input {
                                width: 40px;
                                height: 40px;
                            }

                            p {
                                text-align: left;
                                font-weight: 600;

                                span {
                                    cursor: pointer;
                                    color: var(--color-secondary-light);
                                }
                            }
                        }

                        .login_links {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-weight: 600;

                            a {
                                color: var(--color-secondary-light);
                            }
                        }
                    }
                }

                .password_validator {
                    position: absolute;
                    bottom: 33%;
                    left: 80%;
                    border: 1px solid var(--color-purple-dark);
                    background-color: var(--color-white);
                    border-radius: 18px;
                    padding: 16px 32px;
                    flex-direction: column;
                    gap: 10px;
                    display: none;
                    z-index: 100;
                    width: 260px;

                    p {
                        font-size: 15px;
                    }
                    
                    .validator_title {
                        font-weight: 600;
                    }

                    &.active {
                        display: flex;
                    }

                    .validator_row {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        &.validated {
                            svg {
                                circle {
                                    fill: var(--color-secondary-light);
                                }
                            }
                        }

                        &.error {
                            color: var(--color-error);
                        }
                    }

                    .box_notch {
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        bottom: 65%;
                        left: -9px;
                        z-index: 10;
                        border-bottom: 1px solid var(--color-purple-dark);
                        border-left: 1px solid var(--color-purple-dark);;
                        transform: rotate(45deg);
                        background-color: var(--color-white);
                    }

                }

            }

        }
    }
}

.signup_mobile {
    background: linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%);
    padding: 0 24px;
    display: flex;
    position: relative;

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .error_message {
            color: var(--color-error);
            font-weight: 400!important;
        }

        .text_wrapper {
            width: 100%;

            .main_text {
                text-align: left;
                font-size: 40px;
                font-weight: 700;

                p {
                    background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 24px;
                    font-weight: 700;
                }

                &.login {
                    width: 72%;
                    padding-top: 88px;
                }

            }
        }

        .verification_wrapper {
            padding: 40px 0;
            
            .instructions_wrapper {
                padding: 32px 0;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .resend_button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                border: 0;
                background-color: transparent;
            }

            .buttons_wrapper {
                display: flex;
                flex-direction: column-reverse;
                gap: 12px;
                padding-top: 40px;

                button {
                    color: var(--color-purple-dark);
                    font-weight: 700;
                    background-color: var(--color-white);
                    border: 1px solid var(--color-purple-dark);
                    padding: 10px 80px;
                    border-radius: 8px;

                    &.active {
                        color: var(--color-white);
                        background-color: var(--color-secondary-light);
                        border: 0;
                    }
                }
            }

            .code_wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 16px;

                .code_label {
                    font-weight: 700;
                }

                .code_row {
                    display: flex;
                    gap: 12px;

                    input {
                        width: 15%;
                        text-align: center;
                        border-radius: 8px;
                        font-size: 40px;
                        border: 1px solid var(--color-border-gray);

                        &.error {
                            border-color: var(--color-error);
                        }

                    }
                }
            }
        }

        .message_wrapper {
            padding-top: 40px;
            padding-bottom: 24px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 40px;

            &.padded_down {
                padding-bottom: 256px;
            }

            .message_title_wrapper {
                width: 100%;
            }

            .instructions_wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 100%;
                font-size: 18px;
            }

            .buttons_wrapper {
                button {
                    width: 100%;
                    border: 0;
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--color-white);
                    background-color: var(--color-secondary-light);
                    border-radius: 8px;
                    padding: 16px 0;
                }
            }

            .message_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50%;
                display: flex;
                align-items: flex-end;

                img {
                    width: 100%;
                }
            }
        }

        .form_wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;
            padding-top: 40px;
            padding-bottom: 37px;

            .form_column {
                padding-top: 24px;
                position: relative;

                form {
                    background: white;
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 8px;
                    padding: 24px;

                    .inputs {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;

                        label {
                            font-weight: 700;
                        }

                        input {
                            width: 100%;
                            height: 50px;
                            border: 1px solid var(--color-border-gray);
                            border-radius: 5px;
                            padding: 0 16px;
                            box-sizing: border-box;

                            &.error {
                                border-color: var(--color-error);
                            }
                        }

                        .input_field {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;

                            .password_container {
                                display: flex;
                                gap: 12px;
                                height: 50px;
                                border: 1px solid var(--color-border-gray);
                                background-color: var(--color-white);
                                border-radius: 5px;
                                padding: 0 16px;
                                
                                &.error {
                                    border-color: var(--color-error);
                                }

                                button {
                                    background-color: var(--color-white);
                                    border: 0;
                                    display: flex;
                                    align-items: center;

                                }

                                input {
                                    border: 0;
                                    padding: 0;
                                }

                                svg {
                                    width: 100%;
                                    
                                    path {
                                        fill: var(--color-password-icon)
                                    }
                                }

                            }
                        }
                    }

                    .buttons {
                        padding-top: 32px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 30px;

                        hr {
                            width: 100%;
                        }

                        a {
                            color: var(--color-secondary-light);
                            font-weight: 700;
                        }

                        button {
                            width: 100%;
                            border: 0;
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--color-white);
                            background-color: var(--color-secondary-light);
                            border-radius: 8px;
                            padding: 16px 0;
                        }

                        .privacy_terms {
                            display: flex;
                            gap: 12px;
                            align-items: center;

                            .lbl_checkbox {
                                display: inline-block;
                                padding: 10px;
                            }

                            input {
                                width: 40px;
                                height: 40px;
                            }

                            p {
                                text-align: left;
                                font-weight: 600;

                                span {
                                    cursor: pointer;
                                    color: var(--color-secondary-light);
                                }
                            }
                        }

                        .login_links {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-weight: 600;

                            a {
                                color: var(--color-secondary-light);
                            }
                        }
                    }
                }

                .password_validator {
                    background-color: var(--color-white);
                    padding-top: 30px;
                    flex-direction: column;
                    gap: 10px;
                    display: none;

                    p {
                        font-size: 15px;
                    }
                    
                    .validator_title {
                        font-weight: 600;
                    }

                    &.active {
                        display: flex;
                    }

                    .validator_row {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        &.validated {
                            svg {
                                circle {
                                    fill: var(--color-secondary-light);
                                }
                            }
                        }

                        &.error {
                            color: var(--color-error);
                        }
                    }

                }

            }

            .image_column {
                position: relative;

                img {
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                }
            }

        }
    }
}