:root {
    --color-primary: #1B2033;
    --color-primary-dark: #091026;
    --color-border-gray: #A5A5A5;
    --color-modal-background: #A5A5A599;
	--color-secondary: #f26a6c;
    --color-secondary-light: #fa7d61;
    --color-white: #FFF;
    --swiper-theme-color: #007aff;
    --color-purple-dark: #3f46ad;
    --color-footer-swiper: #7A99FF;
    --color-error-ligth: #FF2E3A;
    --color-error: #FF2E3A;
    --color-footer-icon: #FAFAFA;
    --color-password-icon: #575DB7;
    --color-table-row-selected: #EAF7FF;
    --color-hovered-item: #EBFAFF;
    --color-steper-mobile: #D9D9D9;
    --color-spinner: #0C1137;
    --color-select-blocked: #D4D4D4;
}