.input {
	column-gap: 0.6rem;
	display: flex;
	gap: 12px;
	flex-direction: column;
	width: 100%;

	input {
		margin-top: 0.5rem;
	}

}

textarea {
	font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-size: 16px;
	outline: none;
	height: 160px;
}

.input_container {
	display: flex;
	position: relative;
	width: 100%;

	.input_select {
		cursor: pointer;
		display: flex;
		width: 100%;
	}

	.input_select_blocked {
		display: flex;
		width: 100%;
		color: var(--color-border-gray);
		background: var(--color-select-blocked);
	}

	.input_searcher {
		display: flex;
		width: 100%;
	}

	.input_searcher_with_text {
		cursor: pointer;
		display: flex;
		width: 100%;
		background: var(--color-purple-dark);
		color: var(--color-white);
		font-weight: 600;
	}
}

.input_select_new {
	background: white;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	margin-top: 60px;
	max-height: 200px;
	position: absolute;
	width: 100%;
	z-index: 1;

	input:hover {
		background: var(--color-table-row-selected);
	}
}

.select_container {
	background: white;
	border: 1px solid var(--color-border-gray);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	margin-top: 60px;
	max-height: 200px;
	overflow-y: overlay;
	padding: 2px;
	position: absolute;
	width: calc(100% - 5px);
	z-index: 1;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			align-items: center;
			cursor: pointer;
			display: flex;
			min-height: 2rem;
			padding: 0 1rem;

			&:hover {
				background: var(--color-table-row-selected);
			}
		}
	}

	&::-webkit-scrollbar {
		background-color: transparent;
		width: 1rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
		border: solid 6px transparent;
		border-radius: 1rem;
		box-shadow: inset 0 0 10px 10px var(--color-secondary-light);
	}
}

.select_icon_arrow {
	bottom: 0.8rem;
	position: absolute;
	right: 0.75rem;
}

.select_icon_close {
	bottom: 0.8rem;
	position: absolute;
	right: 0.9rem;
}

.select_icon_close_white {
	cursor: pointer;
	bottom: 0.8rem;
	position: absolute;
	right: 0.9rem;

	path {
		fill: var(--color-white);
	}
}

.check_container {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
}

.error_container {
	display: flex;
	flex-direction: column;
	gap: 1px;

	&_line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&_range_line {
		display: flex;
		gap: 14px;
	}

	.error_message {
		color: var(--color-error-ligth);

		&_font {
			color: var(--color-error-ligth);
			font-weight: 400!important;
			font-size: 14px;
		}
	}	
}